// Override default variables before the import
/*
$body-bg: rgb(255, 255, 255);

$primary: #8E8E93;
$secondary: #FCC42D;

$theme-colors: (
  action: $actionColor
);
*/

$secondary: #DEDEDE;

html, body, #root, .fullHeight {
    height: 100%;
}
.NRHead{
    height: 4rem;
    border-bottom: 1px solid;
}
.NRFoot{
    border-top: 1px solid;
    height: 4rem;
}
.shadow {
    -moz-box-shadow:    3px 0 #ccc;
    -webkit-box-shadow: 3px 0 #ccc;
    box-shadow:         3px 0 #ccc;
}
.NRBody{
    height: calc(100% - 4rem - 4rem);
    overflow-y: auto;
}
.rasterRight{
    border-right: 1px solid;
}
.rasterLeft{
    border-left: 1px solid;
}
.dividerTop{
    border-top: 1px solid;
}
.dividerBottom{
    border-bottom: 1px solid;
}
.feedbackArea{
    position: absolute; 
    bottom: 200px; 
    right: 15px; 
    height: 1px; 
    width: 300px; 
    background-color: #EEEEEE;
}

.lstItmTxt{
    font-size: 1rem;
    margin-bottom: 0;
}
.lstItmDesc{
    font-size: 0.75rem;
    color: #ABABAB;
    margin-top: 0.15rem;
    margin-bottom: 0;
}

input.searchBox{
    text-align: center;
    font-size: 0.75rem !important;

    &::placeholder{
        color: #ABABAB;
    }
    &:-ms-input-placeholder{
        color: #ABABAB;
    }
    &::-webkit-input-placeholder{
        color: #ABABAB;
    }
}

.contextInfoLeft{
    font-size: 0.75rem;
    font-weight: 500;
    color: #ABABAB;
}
.contextInfoRight{
    font-size: 0.75rem;
    color: #ABABAB;
}
.plsWait{
    font-size: 0.75rem;
    color: #ABABAB;
}
.selected-tab {
    color: white !important;
}

.react-bs-table-no-data{
    font-size: 0.8rem;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';